@font-face {
    font-family: "American Typewriter";
    src: local('american-typewriter-medium-bt'), url(./fonts/american-typewriter-medium-bt.woff) format("woff");
}

@font-face {
    font-family: "Cherie Bomb";
    src: local('CherieBomb-Regular'), url(./fonts/CherieBomb-Regular.woff) format("woff");
}

body {
    background-color: deeppink;
    color: aquamarine;
    font-family: "American Typewriter", sans-serif;
    font-size: 18px;
}

div.jumbotron.jumbotron-fluid {
    background-color: aquamarine;

}

h1 {
    font-family: "Cherie Bomb", serif;
}

button {
    background-color: #EA80FC;
    padding: .75rem;
    font-size: 1.75rem;
}
